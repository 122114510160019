<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col offset-md="1" offset-lg="2">
        <Container :paddedTopBottom="false">
          <h2>Kim Henneart</h2>
          <p>
            Als judoka kwam ik vaak bij mijn osteopaat. Daar is mijn interesse
            in het menselijk lichaam ontstaan. Na een opleiding van 4 jaar ben
            ik in 2016 officieel afgestudeerd als osteopaat. Tijdens deze
            opleiding is mijn passie voor de osteopatische filosofie alleen maar
            groter geworden. Osteopathie is een holistische therapie. De
            relaties en verbanden in het lichaam maken klachten erg complex. Dit
            vind ik zo interessant aan mijn beroep. Mijn kennis houd ik
            up-to-date door jaarlijkse bijscholingen.
          </p>
          <p>
            Door mijn Nederlandse roots ben ik met Osteos in contact gekomen en
            ontstond een samenwerking. Op maandag en dinsdag ben ik in de
            praktijk in Berkel. De overige dagen werk ik in een praktijk in
            België.
          </p>
          <h3>Specialismen</h3>
          <p>
            Naast de behandeling van mensen met klachten zoals rugpijn, nekpijn,
            sportletsels, verteringsproblemen, frozen shoulder, tenniselleboog
            en hoofdpijn/migraine, behandel ik ook baby's en kinderen.
          </p>
          <p>
            Ondersteuning van vrouwen voor, tijdens en na de zwangerschap.
            Klachten bij kinderen en baby's, zoals darmkrampen, motorische
            ontwikkeling, voorkeurshouding, reflux, extreem veel huilen.
          </p>
          <h3>Opleiding en Nascholing</h3>
          <ul>
            <li>Osteopathie, International academy of osteopathy, (2016)</li>
            <li>
              Master in kinder- en babyosteopathie, International academy of
              osteopathy, (2020)
            </li>
            <li>
              Pelvische en gynaecologische dysfuncties, Balance institute (2021)
            </li>
            <li>Masterclass van melk naar vaste voeding, Medicourse, (2021)</li>
            <li>Endometriose en adenomyose, Balance Institute, (2022)</li>
            <li>Kinder- en babyosteopathie: de essentie, (2022)</li>
          </ul>

          <img src="../../assets/team/kim.png" alt="Kim" />
        </Container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Container from "../../components/Container.vue";
export default {
  name: "",
  components: { Container }
};
</script>

<style lang="less" scoped>
h2 {
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

h3 {
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
  color: #747769;
  em {
    font-style: normal;
    color: #747769;
  }
}

img {
  max-width: 100%;
}
</style>
